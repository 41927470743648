<template >
  <div id="Tienda">
    <!-- video-c -->
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div id="">
      <div class="bloquetienda">
        <div v-if="backgr !== 'No se encontraron coincidencias'">
          <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" alt="pc" class="pc imgx">
          <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" alt="mo" class="mo imgx">
        </div><!---->
        <div class="noav" v-else> 
        </div>
        <div class="tie-header">
          <h1 class="h-tra">TIENDA</h1>
          <h1><span class="spazul-ti">TIE</span>NDA</h1>
          <h1 class="h-tra">TIENDA</h1>
          <h6>VAMOS CIMARRÓN</h6>
        </div>
      </div>
      <div class="bloquetiendamovil">
        <img src="../assets/bannertiendamovil.png" alt="" />
      </div>

      <div id="storeSection">
        <div class="header-slider"></div>
        <div class="store-sec-cont">
          <div class="scc-slide pc">
            <vueper-slides class="no-shadow" :visible-slides="3" :slide-ratio="1 / 4" :bullets="false" :touchable="false">
              <vueper-slide v-for="slideShop in productdataSlide" :key="slideShop.id">
                <template #content>
                  <div class="store-item">
                    <a :href="'/producto/' + slideShop.url">
                      <div class="item-store-cont">
                        <img :src="url + '/get-product-image/' + slideShop.image" alt="store" />
                      </div>
                    </a>
                    <div class="ti-pr-cont">
                      <div class="cat-genre" v-if="genreName(slideShop.categorias) !== 'N/A'">
                        CATEGORIA:
                        <a :href="genreWeb(slideShop.categorias)">{{
                          genreName(slideShop.categorias)
                        }}</a>
                      </div>
                      <div class="cat-genre" v-else-if="genreName(slideShop.categorias) == 'N/A'">
                        CATEGORIA:
                        <a :href="'/categoria/' + tolc(slideShop.category_name)">{{ slideShop.category_name }}</a>
                      </div>
                      <div class="cat-genre" v-else-if="slideShop.categorias ==
                        'No se encontraron coincidencias'
                        ">
                        CATEGORIA:
                        <a :href="'/categoria/' + tolc(slideShop.category_name)">{{ slideShop.category_name }}</a>
                      </div>
                      <div class="ti-cont">
                        <a :href="'/producto/' + slideShop.url">
                          <h5>{{ slideShop.name }}</h5>
                        </a>
                        <!--  <h6>{{slideShop.size_name}}</h6> -->
                      </div>
                      <div class="pr-cont">
                        <span> $ {{ slideShop.price }}</span> MXN
                      </div>
                    </div>
                    <!-- <div>
                                <a class="comprar-btn" :href="'/producto/'+replace(slideShop.name)">  COMPRAR </a>
                            </div>-->
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <div class="scc-slide mo">
            <vueper-slides class="no-shadow" :visible-slides="1" :bullets="false">
              <vueper-slide v-for="slideShop in productdataSlide" :key="slideShop.id">
                <template #content>
                  <div class="store-item">
                    <a :href="'/producto/' + slideShop.url">
                      <div class="item-store-cont">
                        <img :src="url + '/get-product-image/' + slideShop.image" alt="store" />
                      </div>
                    </a>
                    <div class="ti-pr-cont">
                      <div class="cat-genre" v-if="genreName(slideShop.categorias) !== 'N/A'">
                        CATEGORIA:
                        <a :href="genreWeb(slideShop.categorias)">{{
                          genreName(slideShop.categorias)
                        }}</a>
                      </div>
                      <div class="cat-genre" v-else-if="genreName(slideShop.categorias) == 'N/A'">
                        CATEGORIA:
                        <a :href="'/categoria/' + tolc(slideShop.category_name)">{{ slideShop.category_name }}</a>
                      </div>
                      <div class="cat-genre" v-else-if="slideShop.categorias ==
                        'No se encontraron coincidencias'
                        ">
                        CATEGORIA:
                        <a :href="'/categoria/' + tolc(slideShop.category_name)">{{ slideShop.category_name }}</a>
                      </div>
                      <div class="ti-cont">
                        <a :href="'/producto/' + slideShop.url">
                          <h5>{{ slideShop.name }}</h5>
                        </a>
                        <!--  <h6>{{slideShop.size_name}}</h6> -->
                      </div>
                      <div class="pr-cont">
                        <span> $ {{ slideShop.price }} </span>MXN
                      </div>
                    </div>
                    <!--<a class="comprar-btn" :href="'/producto/'+replace(slideShop.name)">  COMPRAR </a>-->
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
      <!-- fin slider -->

      <a href="/categoria/edicion-limitada">
        <div class="banner_chammaras">
          <img src="../assets/chamarrasbanner.png" alt="" />
        </div>
        <div class="banner_chammarasmovil">
          <img src="../assets/chamarrasmovil.png" alt="" />
        </div>
      </a>

      <a href="/categoria/edicion-limitada"> </a>

      <div class="destacados" v-if="productdataCha[1] && productdataCha[0]">
        <div class="destacados-item">
          <!--   <div class="bloque-div">-->
          <div class="des-cont">
            <h1>CATEGORÍA</h1>
            <h2>HOMBRE</h2>
            <div class="btn red">
              <a href="/categoria/hombre">VER MAS</a>
            </div>
          </div>
          <div class="image-cont" :style="{
            'background-image':
              'url(' + require('../assets/cate-men.png') + ')',
          }" alt="store" />
          <!--  </div>
                              <div class="destacados-data">
                                <div class="cat-genre" v-if="genreName(productdataCha[0].categorias) !== 'N/A'">
                                 CATEGORIA: <a :href="genreWeb(productdataCha[0].categorias)">{{genreName(productdataCha[0].categorias)}}</a>
                                </div>
                                <div class="cat-genre" v-else-if="genreName(productdataCha[0].categorias) == 'N/A'">
                                 CATEGORIA: <a :href="'/categoria/' + tolc(productdataCha[0].category_name)">{{productdataCha[0].category_name}}</a>
                                </div>
                                <div class="cat-genre" v-else-if="productdataCha[0].categorias == 'No se encontraron coincidencias'">
                                 CATEGORIA: <a :href="'/categoria/' + tolc(productdataCha[0].category_name)">{{productdataCha[0].category_name}}</a>
                                </div>
                            <div class="item-name" >
                              <a :href="'/producto/'+replace(productdataCha[0].name)"> 
                                    {{productdataCha[0].name}}</a>
                                </div>
                                <div class="item-price">
                                   <span>$ {{productdataCha[0].price}}</span>MXN
                                </div>
                                <a class="item-btn-comprar" :href="'/producto/'+replace(productdataCha[0].name)">  COMPRAR </a>
                               </div>-->
        </div>
        <div class="destacados-item">
          <div class="des-cont">
            <h1>CATEGORÍA</h1>
            <h2>MUJER</h2>
            <div class="btn black">
              <a href="/categoria/mujer">VER MAS</a>
            </div>
          </div>
          <!--   <div  class="bloque-div">-->
          <div class="image-cont" :style="{
            'background-image':
              'url(' + require('../assets/cate-woman.png') + ')',
          }" alt="store" />
          <!--</div>
                             <div class="destacados-data">
                                <div class="cat-genre" v-if="genreName(productdataCha[1].categorias) !== 'N/A'">
                                 CATEGORIA: <a :href="genreWeb(productdataCha[1].categorias)">{{genreName(productdataCha[1].categorias)}}</a>
                                </div>
                                <div class="cat-genre" v-else-if="genreName(productdataCha[1].categorias) == 'N/A'">
                                 CATEGORIA: <a :href="'/categoria/' + tolc(productdataCha[1].category_name)">{{productdataCha[1].category_name}}</a>
                                </div>
                                <div class="cat-genre" v-else-if="productdataCha[1].categorias == 'No se encontraron coincidencias'">
                                 CATEGORIA: <a :href="'/categoria/' + tolc(productdataCha[1].category_name)">{{productdataCha[1].category_name}}</a>
                                </div>
                              <div class="item-name" >
                                   <a :href="'/producto/'+replace(productdataCha[1].name)"> {{productdataCha[1].name}}</a>
                                </div>
                                <div class="item-price">
                                   <span>$ {{productdataCha[1].price}}</span>MXN
                                </div>
                                
                                 <a class="item-btn-comprar" :href="'/producto/'+replace(productdataCha[1].name)">  COMPRAR </a>
                                </div>-->
        </div>
      </div>

      <a href="/categoria/lo-mas-vendido">
        <div class="lomasvendido">
          <img src="../assets/lomasvendido.png" alt="" />
        </div>
      </a>
      <a href="/categoria/lo-mas-vendido">
        <div class="lomasvendidomovil">
          <img src="../assets/lomasvendidomovil.png" alt="" />
        </div>
      </a>

      <div id="allproducs">
        <div id="items_prod">
          <div v-for="(item, key) in productdata" :key="key" id="item">
            <a :href="'/producto/' + item.url">
              <div class="item-img">
                <img :src="url + '/get-product-image/' + item.image" alt="store" />
              </div>
            </a>
            <div class="cat-genre" v-if="genreName(item.categorias) !== 'N/A'">
              CATEGORIA:
              <a :href="genreWeb(item.categorias)">{{
                genreName(item.categorias)
              }}</a>
            </div>
            <div class="cat-genre" v-else-if="genreName(item.categorias) == 'N/A'">
              CATEGORIA:
              <a :href="'/categoria/' + tolc(item.category_name)">{{
                item.category_name
              }}</a>
            </div>
            <div class="cat-genre" v-else-if="item.categorias == 'No se encontraron coincidencias'">
              CATEGORIA:
              <a :href="'/categoria/' + tolc(item.category_name)">{{
                item.category_name
              }}</a>
            </div>
            <div class="item-name">
              <a :href="'/producto/' + item.url"> {{ item.name }}</a>
            </div>
            <div class="item-price">
              <span>${{ item.price }}</span>MXN
            </div>

            <!--     <a class="item-btn-comprar" :href="'/producto/'+replace(item.name)">  COMPRAR </a>-->
          </div>
        </div>
      </div>

      <div class="movil">
        <div id="storeSection">
          <div class="store-sec-cont">
            <div class="scc-slide pc">
              <vueper-slides class="no-shadow" :visible-slides="3" :slide-ratio="1 / 4" :bullets="false"
                :touchable="false">
                <vueper-slide v-for="slideShop in productdata" :key="slideShop.id">
                  <template #content>
                    <div class="store-item">
                      <a :href="'/producto/' + slideShop.url">
                        <div class="item-store-cont">
                          <img :src="url + '/get-product-image/' + slideShop.image" alt="store" />
                        </div>
                      </a>
                      <div class="ti-pr-cont">
                        <div class="cat-genre" v-if="genreName(slideShop.categorias) !== 'N/A'">
                          CATEGORIA:
                          <a :href="genreWeb(slideShop.categorias)">{{
                            genreName(slideShop.categorias)
                          }}</a>
                        </div>
                        <div class="cat-genre" v-else-if="genreName(slideShop.categorias) == 'N/A'">
                          CATEGORIA:
                          <a :href="'/categoria/' + tolc(slideShop.category_name)
                            ">{{ slideShop.category_name }}</a>
                        </div>
                        <div class="cat-genre" v-else-if="slideShop.categorias ==
                          'No se encontraron coincidencias'
                          ">
                          CATEGORIA:
                          <a :href="'/categoria/' + tolc(slideShop.category_name)
                            ">{{ slideShop.category_name }}</a>
                        </div>
                        <div class="ti-cont">
                          <h5>{{ slideShop.name }}</h5>
                          <!--  <h6>{{slideShop.size_name}}</h6> -->
                        </div>
                        <div class="pr-cont">
                          <span> $ {{ slideShop.price }}</span> MXN
                        </div>
                      </div>
                      <div>
                        <!-- <a class="comprar-btn" :href="'/producto/'+replace(slideShop.name)">  COMPRAR </a>-->
                      </div>
                    </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
            <div class="scc-slide mo">
              <vueper-slides class="no-shadow" :visible-slides="1" :bullets="false">
                <vueper-slide v-for="slideShop in productdata" :key="slideShop.id">
                  <template #content>
                    <div class="store-item">
                      <a :href="'/producto/' + slideShop.url">
                        <div class="item-store-cont">
                          <img :src="url + '/get-product-image/' + slideShop.image" alt="store" />
                        </div>
                      </a>
                      <div class="ti-pr-cont">
                        <div class="cat-genre" v-if="genreName(slideShop.categorias) !== 'N/A'">
                          CATEGORIA:
                          <a :href="genreWeb(slideShop.categorias)">{{
                            genreName(slideShop.categorias)
                          }}</a>
                        </div>
                        <div class="cat-genre" v-else-if="genreName(slideShop.categorias) == 'N/A'">
                          CATEGORIA:
                          <a :href="'/categoria/' + tolc(slideShop.category_name)
                            ">{{ slideShop.category_name }}</a>
                        </div>
                        <div class="cat-genre" v-else-if="slideShop.categorias ==
                          'No se encontraron coincidencias'
                          ">
                          CATEGORIA:
                          <a :href="'/categoria/' + tolc(slideShop.category_name)
                            ">{{ slideShop.category_name }}</a>
                        </div>
                        <div class="ti-cont">
                          <h5>{{ slideShop.name }}</h5>
                          <!--  <h6>{{slideShop.size_name}}</h6> -->
                        </div>
                        <div class="pr-cont">
                          <span> $ {{ slideShop.price }} </span>MXN
                        </div>
                      </div>
                      <!--<a class="comprar-btn" :href="'/producto/'+replace(slideShop.name)">  COMPRAR </a>-->
                    </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </div>
        <!-- fin slider -->
      </div>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex"; 
let urlpath = require('../global/url');

export default {
  data() {
    return {
      urlpath,
      backgr: []
    }
  },
  components: {
    HeaderHome,
    Footer,
    VueperSlides,
    VueperSlide,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  async created() {
    this.findAll();
    this.findByCategoryName2();
    this.findByCategoryNameCha();
      let payload2 = {
        id: 'Tienda',
        option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    productdata() {
      //console.log(this.$store.getters['product/data'])
      let arr = this.$store.getters["product/data"];
      let narr = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].status == "ACTIVO") {
          narr.push(arr[i]);
        }
      } /**/
      return narr;
    },
    productdataSlide() {
      //return this.$store.getters['product/data4'];
      let arr = this.$store.getters["product/data4"];
      let narr = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].status == "ACTIVO") {
          narr.push(arr[i]);
        }
      }
      return narr;
    },
    productdataCha() {
      return this.$store.getters["product/data5"];
    },
  },

  methods: {
    ...mapActions("product", ["findAll"]),
    ...mapActions("product", ["findByCategoryName2"]),
    ...mapActions("product", ["findByCategoryNameCha"]),
    ...mapActions("product", ["findById"]),
    ...mapActions("product", ["getImageFile"]),
    ...mapActions("cabeceras", ["getCabecerasById"]), 

    genreName(genre) {
      let name = "N/A";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "MUJER";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "HOMBRE";
        } else if (genre[i].category_name == "NIÑO") {
          name = "NIÑO";
        }
      }
      return name;
    },

    tolc(palabra) {
      let word = palabra.replaceAll(" ", "-");
      let newword = word
        .normalize("NFD")
        .replace(
          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
          "$1"
        )
        .normalize();
      return newword.toLowerCase();
    },

    genreWeb(genre) {
      let name = "#";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "/categoria/mujer";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "/categoria/hombre";
        } else if (genre[i].category_name == "NIÑO") {
          name = "/categoria/niño";
        }
      }
      return name;
    },
    findAll: async function () {
      let result = await this.$store.dispatch("product/findAll", {
        option: "tienda",
      });
      this.info = result;
      return result;
    },
    findByCategoryName2: async function () {
      let result = await this.$store.dispatch("product/findByCategoryName2", {
        option: "product/by/categoryname",
        name: "SLIDE TIENDA",
      });
      this.info = result;
      return result;
    },
    findByCategoryNameCha: async function () {
      let name = this.$route.params.name;
      let result = await this.$store.dispatch("product/findByCategoryNameCha", {
        option: "product/by/categoryname",
        name: "LO MAS DESTACADO",
      });
      this.info = result;
      return result;
    },
    productDetail: function (name) {
      return (name = name.replaceAll(" ", "-").replace("/", "_"));

      this.$router.push("/producto/" + name).catch((err) => { });
    },
    replace(name) {
      return (name = name.replaceAll(" ", "-").replace("/", "_"));
    },
    selectSlidePro(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].categorias.category_name == "SLIDE TIENDA") {
          data.splice(i, 1);
          i--;
        }
      }
      return data;
    },
  },

  /**titulo pagina */
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Tienda - Cimarrones de Sonora";
      },
    },
  },
};
</script>

<style scoped>
.banner_chammarasmovil {
  display: none;
}

.bloquetiendamovil {
  display: none;
}

.lomasvendidomovil {
  display: none;
}

.movil {
  display: none;
}

#Tienda #storeSection {
  background: #ffffff;
}

#Tienda #storeSection .ti-pr-cont {
  display: block;
}

#Tienda #storeSection .ti-pr-cont h5 {
  font-size: 1.25vw;
  font-family: "Rajdhani";
  text-align: center;
  color: #032a4b;
  padding-top: 1vw;
}

#Tienda #storeSection .pr-cont {
  margin: auto;
  padding: 0.5vw 1.5vw;
}

.comprar-btn {
  width: 22vw !important;
  font-size: 0.938vw !important;
  padding: 0 8.488vw !important;
}

.comprar-btn:hover {
  padding: 0px 4.2vw !important;
  background-position-x: 11.521vw !important;
}

#Tienda #storeSection .item-store-cont {
  border: 1px solid #8293a1;
}

#Tienda #storeSection .pr-cont {
  font-size: 0.9375vw;
  font-family: "Rajdhani";
}

#Tienda #storeSection .pr-cont span {
  font-size: 0.9375vw;
  font-family: "Rajdhani" !important;
}

#Tienda #storeSection button.vueperslides__arrow.vueperslides__arrow--prev {
  background-image: url(../assets/arrowleft14.png) !important;
}

#Tienda #storeSection button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url(../assets/arrowrigth14.png) !important;
}

.banner_chammaras img,
.lomasvendido img {
  width: 100%;
}

.header-slider {
  height: 5.5vw;
  background: #b70d1d;
}

.destacados {
  display: flex;
}

.destacados-item {
  width: 49.3125vw;
  height: 43.854vw;

  /*margin-right: 1.1979166666666667VW;*/
}

.destacados-item .image-cont {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: 0.5s;
  background-position-x: center;
  background-position-y: center;
}

.destacados-item:hover .image-cont {
  background-size: 110%;
  background-position-x: center;
  background-position-y: top;
}

.red {
  background: #b70d1d;
  /*height: 43.848437499999996VW;
     padding-top: 7vw;
     padding-left: 11vw;*/
}

.black {
  background: #000000;
  /*height: 43.848437499999996VW;
     padding-top: 7vw;
     padding-left: 11vw;*/
}

.des-cont {
  position: absolute;
  padding: 18.073vw 11.3vw 9.219vw;
  font-family: "Rajdhani";
  font-style: normal;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.destacados .btn {
  width: 14.635vw;
  height: 5.365vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  margin-top: 5.573vw;
  transition: 0.5s;
}

.destacados .btn.red:hover {
  background: #830813;
}

.destacados .btn.black:hover {
  background: #374450;
}

.destacados .btn a {
  width: 14.635vw;
  height: 5.365vw;
  font-size: 1.042vw;
  line-height: 100.49%;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.destacados .btn:active {
  background-color: #000000 !important;
  border-color: transparent !important;
}

.des-cont h1 {
  font-weight: 800;
  font-size: 1.042vw;
  line-height: 100.49%;
  width: 100%;
  margin: 0;
}

.des-cont h2 {
  font-size: 6.771vw;
  line-height: 100.49%;
  margin: 0;
}

.red img,
.black img {
  width: 23.84375vw;
  height: 26.510416666666664vw;
}

.destacados-data {
  margin: auto;
  margin-top: 3.3390625vw;
}

.destacados {
  margin-top: 0.99vw;
  margin-bottom: 0.99vw;
  display: flex;
  justify-content: space-between;
}

#allproducs {
  width: 89vw;
  height: auto;

  margin-top: 10vw;
  margin-left: 5vw;
}

#items_prod {
  display: flex;
  flex-wrap: wrap;
}

#allproducs #item {
  margin-right: 1vw;
  margin-bottom: 5vw;
  transition: 0.5s;
}

#allproducs #item:hover {
  transform: scale(1.05);
}

#allproducs #item .item-img {
  border: 1px solid #8293a1;
  border-radius: 1.2vw;
  padding: 3vw 2vw 2vw 2vw;
  width: 28.6vw;
  margin-bottom: 2vw;
}

#allproducs #item .item-img img {
  width: 23.84375vw;

  height: 26.510416666666664vw;
}

.item-name {
  width: 23.729166666666668vw;
  margin: auto;
  margin-top: 1.5vw;
  text-align: center;
}

.item-name a {
  font-size: 1.25vw;
  font-family: "Rajdhani";
  color: #032a4b;
}

.item-price {
  background: #968220;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: 2.24vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375vw;
  font-family: "Rajdhani";
  color: #ffffff;

  margin: auto;
  padding: 0.5vw 1.5vw;
  margin-top: 0.5vw;
}

.item-price span {
  font-size: 0.9375vw;
  font-family: "Rajdhani" !important;
}

.item-btn-comprar {
  font-size: 1.3vw;
  background: #032a4b;
  color: white !important;
  width: 22vw;
  height: 4.688vw;
  letter-spacing: 0.1vw;
  display: flex;
  align-items: center;
  padding: 0 7.7vw;
  font-family: 1.042vw;
  font-family: "Rajdhani";
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
  margin-top: 1vw;
  background-image: url("../assets/arrow2.png") !important;
  background-size: 0 !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position-x: 0 !important;
  background-position-y: center !important;
  transition-delay: 0 !important;
  transition: 0.25s;
}

.item-btn-comprar:hover {
  background: #021729;
  color: white !important;
  text-decoration: none !important;
  padding: 0 3.7vw;
  background-size: 5vw !important;
  background-position-x: 14vw !important;
}

.bloquetienda {
  position: relative;
}

.bloquetienda .tie-header {
  width: 100%;
  height: 37.135vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background-image: url(../assets/da-tienda.png);
  background-size: 100%;*/
  background-repeat: no-repeat;
  padding: 7.93125vw 28.135vw 4.31vw 34.7vw;
  transition: 0.5s;
  position: relative;
}

.bloquetienda .tie-header h1,
.filosofia .tie-header h6 {
  width: 100%;
  cursor: default;
  /* filter: drop-shadow(2px 2px 4px rgba(0,0,0,.507));*/
}

.bloquetienda .tie-header h1 {
  font-size: 8.333333333333332vw;
  line-height: 6.375vw;
  color: #ffffff;
  font-family: Rajdhani;
  background-position: 50%;
  margin-bottom: 0.1vw;
}

.bloquetienda .h-tra {
  -webkit-text-stroke: 1px #fff;
  color: transparent !important;
}

span.spazul-ti {
  color: #032a4b;
}

.bloquetienda .tie-header h6 {
  font-size: 0.729vw;
  line-height: 110.49%;
  letter-spacing: 0.6vw;
  color: #ffffff !important;
  font-family: "Rajdhani";
  padding-left: 13.5vw;
  text-align: right;
  padding-top: 1vw;
}
img.pc.imgx { 
  display: block;
}
  img.mo.imgx { 
    display: none;
}

@media (max-width: 768px) {
img.pc.imgx { 
  display: none;
}
  img.mo.imgx {
    top: 21vw;
  display: block;
    height: 51.208vw;
}
  .comprar-btn {
    height: 18.116vw !important;
    font-size: 3vw !important;
    padding: 0 32vw !important;
  }

  .comprar-btn:hover {
    padding: 0 17vw !important;
    background-position-x: 48.521vw !important;
  }

  .bloquetienda {
    display: none;
  }

  .movil {
    display: block;
  }

  .header-slider {
    display: none;
  }

  #Tienda #storeSection .ti-pr-cont h5 {
    font-size: 4.25vw;
  }

  #Tienda #storeSection .pr-cont span {
    font-size: 3.9375vw;
  }

  #Tienda #storeSection .pr-cont {
    font-size: 3.9375vw;
  }

  .destacados {
    display: block;
  }

  .destacados-item {
    width: 86vw;
    height: 76.227vw;
    margin-bottom: 5vw;
    margin-left: 6.521739130434782vw;
  }

  .destacados-data {
    margin-left: 4vw;
  }

  /*.black, .red{
        height: 71.848437VW;
    }*/
  .des-cont h1 {
    font-size: 2.415vw;
    line-height: 100.49%;
  }

  .des-cont h2 {
    font-size: 12.077vw;
    line-height: 100.49%;
  }

  .destacados .btn {
    width: 25.362vw;
    height: 9.662vw;
  }

  .destacados .btn a {
    font-size: 2.415vw;
    line-height: 100.49%;
  }

  .des-cont {
    padding: 30.193vw 19.2vw 16.193vw;
  }

  .red img,
  .black img {
    width: 62.84375vw;
    height: 51.510417vw;
  }

  #items_prod {
    display: block;
  }

  .item-name {
    font-size: 3.85vw;
    width: 64.729167vw;
    margin: auto;
  }

  .item-name a {
    font-size: 3.25vw;
    font-family: "Rajdhani";
    color: #032a4b;
  }

  .item-price {
    height: 7.24vw;
    width: 26vw !important;
    margin: auto;
    margin-bottom: 4vw;
  }

  .item-price span,
  .item-price {
    font-size: 3.9375vw;
  }

  .item-btn-comprar {
    background: #032a4b;
    color: #fff;
    width: 75.01vw;
    height: 16.688vw;
    padding: 0 28vw;
    font-size: 4vw;
  }

  .item-btn-comprar:hover {
    background: #021729;
    color: white !important;
    text-decoration: none !important;
    padding: 0 11.7vw;
    background-size: 15vw !important;
    background-position-x: 49vw !important;
  }

  #allproducs {
    display: none;
  }

  .bloquetiendamovil {
    display: block;
    height: 53.864734299516904vw;
    padding-top: 24vw;
    margin-bottom: 29vw;
  }

  .bloquetiendamovil img {
    width: 100%;
  }

  .banner_chammaras {
    display: none;
  }

  .banner_chammarasmovil {
    display: block;
    margin-bottom: 5vw;
  }

  .banner_chammarasmovil img {
    width: 100%;
  }

  .lomasvendidomovil {
    display: block;
  }

  .lomasvendidomovil img {
    width: 100%;
  }

  .lomasvendido {
    display: none;
  }
}
</style>